import React from 'react';

import Layout from '~components/layout';
import PageContent from '~containers/page-content';

const CustomerCare = props => {
  const {
    params: {customerCareLink},
  } = props;

  return (
    <Layout>
      <PageContent {...props} link={customerCareLink} />
    </Layout>
  );
};

export default CustomerCare;
